<template>
  <div>
    <div class="bg">
      <img src="@/assets/images/partner_bg.png" alt="">
      <div class="content">
        <div class="title">合作伙伴</div>
        <div class="desc">欢迎与更多优秀企业加入AI资管</div>
        <!-- <div class="action">免费试用</div> -->
      </div>
    </div>
    <div class="statistic">
      <div class="title">我们的脚步</div>
      <div class="list-wrap">
        <div class="item-wrap">
          <div class="name">覆盖地区</div>
          <div class="data">15</div>
        </div>
        <div class="item-wrap">
          <div class="name">客户数</div>
          <div class="data">2880</div>
        </div>
        <div class="item-wrap">
          <div class="name">日均活跃</div>
          <div class="data">213,000</div>
        </div>
      </div>
    </div>
    <div class="customer">
      <div class="title">合作客户</div>
      <div class="customer-content">
        <div class="layout">
          <div class="label">政府</div>
          <img class="img" src="@/assets/images/pic_zflogo.png" alt="">
        </div>
        <div class="layout" style="margin: 0 4rem;">
          <div class="label">企业</div>
          <img class="img" src="@/assets/images/pic_qylogo.png" alt="">
        </div>
        <div class="layout">
          <div class="label">金融</div>
          <img class="img" src="@/assets/images/pic_jrlogo.png" alt="">
        </div>
      </div>
    </div>
    <div class="kefu">
      <img src="@/assets/images/kefu.png" alt="">
    </div>
    <Footer />
  </div>
</template>

<script>

import Footer from '@/components/Footer'

export default {
  components: {
    Footer
  },
  data() {
    return {

    }
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>
.bg{
  position: relative;
  img{
    width: 100%;
    display: block;
  }
  .content{
    position: absolute;
    top: 45%;
    left: 25%;
    .title{
      font-size: 4.6rem;
      font-weight: bold;
      color: #fff;
    }
    .desc{
      font-size: 2.4rem;
      font-weight: 100;
      color: #fff;
      margin: 5px 0 40px;
    }
    .action{
      width: 120px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-image: linear-gradient(to right, #3538FB, #92F0FE);
      border-radius: 8px;
      font-size: 1.6rem;
      color: #fff;
      cursor: pointer;
    }
    .action:hover{
      background-image: linear-gradient(to right, #2225DA, #92D3FE);
    }
  }
}
.statistic{
  margin-top: 6rem;
  margin-bottom: 4rem;
  .title{
    font-size: 3.6rem;
    font-weight: bold;
    margin-bottom: 4.5rem;
    text-align: center;
    color: #333;
  }
  .list-wrap{
    display: flex;
    justify-content: center;
    .item-wrap{
      width: 30rem;
      .name{
        font-size: 1.6rem;
        margin-bottom: -10px;
      }
      .data{
        font-size: 6rem;
        font-weight: bold;
        color: #0066FF;
      }
    }
  }
}
.customer{
  background-color: #F0F8FE;
  padding-top: 6rem;
  padding-bottom: 6rem;
  .title{
    font-size: 3.6rem;
    font-weight: bold;
    margin-bottom: 4.5rem;
    text-align: center;
    color: #333;
  }
  .customer-content{
    display: flex;
    justify-content: center;
    width: 120rem;
    margin: 0 auto;
    .layout{
      .label{
        font-size: 2rem;
        height: 4.4rem;
        line-height: 4.4rem;
        background-image: linear-gradient(to left, #2194F8, #5A6EFB);
        color: #fff;
        text-align: center;
        margin-bottom: 2rem;
      }
      .img{
        width: 35rem;
        display: block;
      }
    }
  }
}
.kefu{
  display: flex;
  justify-content: center;
  img{
    width: 1200px;
    display: block;
  }
}
</style>